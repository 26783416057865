import React, { useState, useEffect } from 'react';
import { Box, Button, Input, Heading, Flex, Text, useToast, Spinner } from '@chakra-ui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_VERCEL_API_URL
    : process.env.REACT_APP_LOCAL_API_URL;

const ManageAboutUs = () => {
  const [conditions, setAbout] = useState({ title: '', content: '' });
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const toast = useToast();

  // Fetch the current About on component mount or reload
  useEffect(() => {
    fetchAbout();
  }, [reload]);

  // Fetch About data from the API
  const fetchAbout = async () => {
    setLoading(true); // Start loading
    try {
      const response = await fetch(`${API_BASE_URL}api/aboutus`);
      const data = await response.json();
      if (data) {
        setAbout(data); // Set conditions only if data exists
      }
    } catch (error) {
      console.error('Failed to fetch conditions:', error);
    } finally {
      setLoading(false); // Stop loading once the fetch completes
    }
  };

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAbout({ ...conditions, [name]: value });
  };

  // Handle content change in the ReactQuill editor
  const handleContentChange = (content) => {
    setAbout({ ...conditions, content });
  };

  // Update the About with a PUT request
  const handleUpdateAbout = async () => {
    const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
    try {
      const response = await fetch(`${API_BASE_URL}api/aboutus`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
        },
        body: JSON.stringify(conditions), // Send the updated About
      });

      if (!response.ok) {
        throw new Error('Failed to update conditions');
      }

      setReload(!reload); // Trigger reload after updating
      // Show success toast notification
      toast({
        title: "Sucesso!",
        description: "Condições de Uso foram atualizadas com sucesso.",
        status: "success",
        duration: 5000, // Duration in milliseconds (5 seconds)
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating conditions:', error);
    }
  };

// Define extended toolbar options for ReactQuill editor
const modules = {
  toolbar: [
    // Dropdowns for headers, font, and font size
    [{ 'header': '1' }, { 'header': '2' }, { 'header': [3, 4, 5, false] }],
    [{ 'font': [] }],
    [{ 'size': ['small', false, 'large', 'huge'] }], // Font size options

    // Text styling options
    ['bold', 'italic', 'underline', 'strike'], // Bold, italic, underline, strike-through
    [{ 'color': [] }, { 'background': [] }], // Text color and background color

    // List and indent options
    [{ 'list': 'ordered' }, { 'list': 'bullet' }], // Ordered and bullet lists
    [{ 'indent': '-1' }, { 'indent': '+1' }], // Indentation controls (outdent and indent)

    // Text alignment options
    [{ 'align': [] }],

    // Blockquote and code block
    ['blockquote', 'code-block'], // Blockquotes and code blocks

    // Insert links, images, and videos
    ['link', 'image', 'video'], // Insert link, image, and video

    // Cleaning up formatting
    ['clean'], // Remove formatting

    // Text direction (left-to-right or right-to-left)
    [{ 'direction': 'rtl' }] // Text direction control
  ],
};


  const formats = [
    'header', 'bold', 'italic', 'underline', 'strike',
    'list', 'bullet', 'link', 'image'
  ];

  if (loading) {
    // Show a spinner while the data is being fetched
    return (
      <Box p={4} textAlign="center">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box p={4}>
      <Heading size="md" mb={4}>Gestão da página Sobre Nós</Heading>
      <Flex
        direction="column"
        gap={4}
        style={{
          height: '800px', // Set a fixed height for the container
          overflowY: 'scroll', // Enable vertical scrolling if content exceeds height
          padding: '10px', // Optional padding for better readability
          border: '1px solid #ccc', // Optional border for better visibility
          borderRadius: '4px', // Optional border radius for styling
        }}
      >
        <Input
          placeholder="Título"
          name="title"
          value={conditions.title}
          onChange={handleInputChange}
        />
        <ReactQuill
          theme="snow"
          value={conditions.content}
          onChange={handleContentChange}
          modules={modules}
          formats={formats}
        />        
      </Flex>
      <Flex direction="column" gap={4}>
        <Button onClick={handleUpdateAbout} colorScheme="blue">
          Atualizar Condições de Uso
        </Button>
      </Flex>

      {/* Preview of the About */}
      <Box mt={8}>
        <Heading size="lg" p={6}>Como o Celular Mostra o Documento</Heading>
        <Text fontWeight="bold">{conditions.title}</Text>
        <Box
          dangerouslySetInnerHTML={{ __html: conditions.content }}
          style={{
            height: '800px', // Set a fixed height for the content box
            width:'760px',
            overflowY: 'scroll', // Enable vertical scrollbar
            padding: '10px', // Optional: Add padding for better readability
            border: '1px solid #ccc', // Optional: Add a border to define the box
            borderRadius: '4px', // Optional: Slight border radius for styling
          }}
        />
      </Box>
      
    </Box>
  );
};

export default ManageAboutUs;
