import React, { useEffect, useState } from 'react';
import {
  Box,
  chakra,
  Flex,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  useTheme,
  VisuallyHidden,
  Image,
} from '@chakra-ui/react';
import { FaInstagram, FaTwitter, FaYoutube, FaFacebook } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_VERCEL_API_URL
    : process.env.REACT_APP_LOCAL_API_URL;

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const ListHeader = ({ children, textColor }) => {
  const theme = useTheme();
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2} color={textColor} fontFamily={theme.fonts.heading}>
      {children}
    </Text>
  );
};

const Footer = () => {
  const theme = useTheme();
  const brandColor = theme.colors?.brand?.[500] || 'blue.500';
  const textColor = theme.colors?.text?.default || 'gray.700';
  const [footerLogo, setFooterLogo] = useState(null);
  const [socialLinks, setSocialLinks] = useState({
    facebook: '',
    instagram: '',
    twitter: '',
    youtube: '',
    facebookEnabled: false,
    instagramEnabled: false,
    twitterEnabled: false,
    youtubeEnabled: false,
  });
  const [contacts, setContacts] = useState({
    whatsapp: 'Loading...'
  });

  useEffect(() => {
    fetchFooterLogo();
    fetchSocialLinks();
    fetchContacts();
  }, []);

  const fetchFooterLogo = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}api/settings/logo?type=rodape`);
      const data = await response.json();
      setFooterLogo(`data:image/png;base64,${data.base64Data}`);
    } catch (error) {
      console.error('Failed to fetch footer logo:', error);
    }
  };

  const fetchContacts = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}api/contacts`);
      const data = await response.json();
      setContacts(data);
    } catch (error) {
      console.error('Error fetching contact details:', error);
    }
  };

  const fetchSocialLinks = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}api/contacts`);
      const data = await response.json();
      setSocialLinks(data);
    } catch (error) {
      console.error('Failed to fetch social links:', error);
    }
  };

  return (
    <Box bg={brandColor} color={useColorModeValue('gray.700', 'gray.200')} fontFamily={theme.fonts.body}>
      <Flex align={'center'} py={10} justify="center">
        <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={10} alignItems="center">
          <Stack spacing={6} align={"center"}>
            <Box>
              {footerLogo && (
                <Image src={footerLogo} alt="Footer Logo" height="100px" />
              )}
            </Box>
            <Text fontSize={'sm'} color={textColor} textAlign="center">
              © 2024 Estúdio Boreal. Todos os direitos reservados.
            </Text>
            <Stack direction={'row'} spacing={6} justify="center">
              {socialLinks.twitterEnabled && (
                <SocialButton label={'Twitter'} href={`http://www.twitter.com/${socialLinks.twitter}`}>
                  <FaTwitter color={textColor} />
                </SocialButton>
              )}
              {socialLinks.youtubeEnabled && (
                <SocialButton label={'YouTube'} href={`http://www.youtube.com/${socialLinks.youtube}`}>
                  <FaYoutube color={textColor} />
                </SocialButton>
              )}
              {socialLinks.instagramEnabled && (
                <SocialButton label={'Instagram'} href={`http://www.instagram.com/${socialLinks.instagram}`}>
                  <FaInstagram color={textColor} />
                </SocialButton>
              )}
              {socialLinks.facebookEnabled && (
                <SocialButton label={'Facebook'} href={`http://www.facebook.com/${socialLinks.facebook}`}>
                  <FaFacebook color={textColor} />
                </SocialButton>
              )}
            </Stack>
          </Stack>
          <Stack align={'left'}>
            <ListHeader textColor={textColor}>Empresa</ListHeader>
            <Link to="/sobrenos" style={{ color: textColor }}>Sobre nós</Link>
            <Link to="/contatos" style={{ color: textColor }}>Contatos</Link>
          </Stack>
          <Stack align={'left'}>
            <ListHeader textColor={textColor}>Suporte</ListHeader>
            <Link to="/ajuda" style={{ color: textColor }}>Ajuda</Link>
            <Link to="/politica_privacidade" style={{ color: textColor }}>Política de privacidade</Link>
          </Stack>
        </SimpleGrid>
      </Flex>
    </Box>
  );
};

export default Footer;
