import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Stack,
  Text,
  Heading,
  Image,
  SimpleGrid,
  List,
  ListItem,
  Grid,
  GridItem,
  HStack,
  Divider,
  Flex,
  Spacer,
  Input,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import ZoomableImage from './ZoomImage';
import ProductCarousel from '../Carousel/ProductCarousel.js';
import { useUser } from '../User/UserContext'; // Import the UserContext

const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_VERCEL_API_URL
    : process.env.REACT_APP_LOCAL_API_URL;

const ProductPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [product, setProduct] = useState(null);
  const [mainImage, setMainImage] = useState('');
  const [stock, setStock] = useState([]);
  const [availableColors, setAvailableColors] = useState([]);
  const [availableSizes, setAvailableSizes] = useState([]);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [sizes, setSizes] = useState([]);
  const [quantity, setQuantity] = useState(1); // Default quantity is 1
  const [maxQuantity, setMaxQuantity] = useState(0); // Max quantity for the selected color and size
  const { cart, setCart } = useUser(); // Use the UserContext
  //const [showAllImages, setShowAllImages] = useState(false); // State to toggle displaying all images
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}api/products/${id}`);
        const data = await response.json();
        setProduct(data);
        setMainImage(data.images[0]);
      } catch (error) {
        console.error('Failed to fetch product:', error);
      }
    };

    const fetchStock = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}api/stock/stock/${id}`);
        const data = await response.json();
         // Filtered stock already handled by the backend
        // Filtered stock already handled by the backend
        setStock(data);

        const colors = data
          .map(stockItem => stockItem.colorId)
          .filter((value, index, self) => self.findIndex(v => v._id === value._id) === index);

        const sizes = data
          .map(stockItem => stockItem.sizeId)
          .filter((value, index, self) => self.findIndex(v => v._id === value._id) === index);

        setAvailableColors(colors);
        setSizes(sizes);
      } catch (error) {
        console.error('Failed to fetch stock:', error);
      }
    };

    // Clear previous stock-related states before fetching new data
    setStock([]);
    setAvailableColors([]);
    setAvailableSizes([]);
    setSelectedColor(null);
    setSelectedSize(null);
    setQuantity(1);
    setMaxQuantity(0);

    fetchProduct();
    fetchStock();
  }, [id]);

  const handleImageClick = (base64Image) => {
    setMainImage(base64Image);
  };

  const handleColorClick = (color) => {
    setSelectedColor(color);
    setSelectedSize(null);
    setQuantity(1);
    setMaxQuantity(0);
    const sizesForColor = stock
      .filter(stockItem => stockItem.colorId._id === color._id)
      .map(stockItem => stockItem.sizeId);
    setAvailableSizes(sizesForColor);
  };

  const handleSizeClick = (size) => {
    setSelectedSize(size);
    const selectedStock = stock.find(stockItem => stockItem.colorId._id === selectedColor._id && stockItem.sizeId._id === size._id);
    if (selectedStock) {
      setMaxQuantity(selectedStock.quantity);
      setQuantity(1);
    } else {
      setMaxQuantity(0);
      setQuantity(0);
    }
  };

  const handleQuantityChange = (value) => {
    if (!product.budgetOn){
      const newQuantity = Math.max(1, Math.min(value, maxQuantity));
      setQuantity(newQuantity);
    }else{
      setQuantity(value);
    }
  };

  const handleAddToCart = (isBudget) => {
    if (isBudget || (selectedColor && selectedSize)) {
      const cartItem = {
        productId: product._id,
        name: product.name,
        price: isBudget ? null : product.price,
        color: selectedColor,
        size: selectedSize,
        quantity,
        budgetOn: isBudget,
      };
  
      setCart([...cart, cartItem]);

      toast({
        title: 'Adicionado ao Carrinho',
        description: 'Produto foi adicionado ao Carrinho',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
  
      navigate('/cart');
    } else {
      toast({
        title: 'Error',
        description: 'Please select color and size before adding to cart.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleBuy = () => {
    alert(`Comprar: ${product.name}, Cor: ${selectedColor ? selectedColor.name : ''}, Tamanho: ${selectedSize ? selectedSize.name : ''}, Quantidade: ${quantity}`);
  };

  const isSizeAvailable = (sizeId) => {
    return availableSizes.some(size => size._id === sizeId);
  };

  const displayedImages = product && product.images ? product.images.slice(currentImageIndex, currentImageIndex + 5) : [];

  if (!product) {
    return <div>Produto não encontrado</div>;
  }

  return (
    <Container maxW={'8xl'}>
      <Button onClick={() => navigate(-1)} mt={4} mb={4}>
        Voltar
      </Button>
      <SimpleGrid
        columns={{ base: 1, lg: 2 }}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 18, md: 24 }}
      >
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(5, 1fr)' }} gap={2}>
          <GridItem colSpan={{ base: 1, md: 1 }} order={{ base: 2, md: 1 }}>
          <HStack
            spacing={2}
            overflowX="auto"
            flexDirection={{ base: 'row', md: 'column' }}
            py={2}
          >
            {product && product.images.length > 5 && currentImageIndex > 0 && (
              <Button
                onClick={() => setCurrentImageIndex(currentImageIndex - 1)}
                w={{ base: '30px', md: '30px' }}
                h={{ base: '100px', md: '30px' }}
                fontSize="lg"
              >
                &lt;
              </Button>
            )}
            {displayedImages.map((base64Image, index) => (
              <Image
                key={index}
                src={`data:image/png;base64,${base64Image}`}
                alt={`Product Image ${index}`}
                fit={'cover'}
                align={'center'}
                w={{ base: '100px', md: '100%' }}
                h={{ base: '100px', md: '100px' }}
                cursor={'pointer'}
                onClick={() => handleImageClick(base64Image)}
                border={mainImage === base64Image ? '2px solid #000' : 'none'}
              />
            ))}
            {product && product.images.length > 5 && currentImageIndex + 5 < product.images.length && (
              <Button
                onClick={() => setCurrentImageIndex(currentImageIndex + 1)}
                w={{ base: '30px', md: '30px' }}
                h={{ base: '100px', md: '30px' }}
                fontSize="lg"
              >
                &gt;
              </Button>
            )}
          </HStack>
          </GridItem>
          <GridItem colSpan={{ base: 1, md: 4 }} order={{ base: 1, md: 2 }}>
            <ZoomableImage
              src={`data:image/png;base64,${mainImage}`}
              alt={product.name}
            />
          </GridItem>
        </Grid>

        <Stack spacing={{ base: 6, md: 10 }}>
          <Box as={'header'}>
            <Heading lineHeight={1.1} fontWeight={500} fontSize={{ base: '2xl', sm: '3xl', lg: '4xl' }}>
              {product.name}
            </Heading>
            <Text color={'gray.900'} fontWeight={300} fontSize={'2xl'}>
              {product.budgetOn ? 'Sob Consulta' : `R$${product.price.toFixed(2)}`}
            </Text>
          </Box>
          {product.budgetOn && (
              <>
                <Flex align="center" mb={4}>
                  <Text mr={2}>Quantidade</Text>
                  <Button
                    size="sm"
                    onClick={() => handleQuantityChange(quantity - 1)}
                    isDisabled={quantity <= 1}
                  >
                    -
                  </Button>
                  <Input
                    value={quantity}
                    onChange={(e) => handleQuantityChange(Number(e.target.value))}
                    width="60px"
                    textAlign="center"
                    mx={2}
                  />
                  <Button
                    size="sm"
                    onClick={() => handleQuantityChange(quantity + 1)}
                  >
                    +
                  </Button>
                </Flex>
                <Button
                  colorScheme="yellow"
                  onClick={() => handleAddToCart(true)}
                  size="lg"
                >
                  Orçar
                </Button>
              </>
            )}
          {!product.budgetOn && (
            <Stack spacing={{ base: 4, sm: 6 }} direction={'column'}>
              <Box>
                <Text fontSize={{ base: '16px', lg: '18px' }} color={'yellow.500'} fontWeight={'500'} textTransform={'uppercase'} mb={'4'}>
                  Cores Disponíveis
                </Text>
                {selectedColor && (
                  <Text mt={2} fontSize="lg" fontWeight="bold" textAlign="left">
                    {selectedColor.name}
                  </Text>
                )}
                <Spacer my={2}/>
                <HStack spacing={2}>
                  {availableColors.map((color) => (
                    <Box
                      key={color._id}
                      w={'30px'}
                      h={'30px'}
                      bg={color.hex}
                      borderRadius={'full'}
                      border={'1px solid gray'}
                      cursor={'pointer'}
                      onClick={() => handleColorClick(color)}
                      position="relative"
                    >
                      {selectedColor && selectedColor._id === color._id && (
                        <Box
                          position="absolute"
                          top="-5px"
                          right="-5px"
                          bottom="-5px"
                          left="-5px"
                          borderRadius="full"
                          border="4px solid black"
                        />
                      )}
                    </Box>
                  ))}
                </HStack>
              </Box>
              <Box>
                {selectedColor && (
                  <>
                    <Text fontSize={{ base: '16px', lg: '18px' }} color={'yellow.500'} fontWeight={'500'} textTransform={'uppercase'} mb={'4'}>
                      Tamanhos Disponíveis
                    </Text>
                    <HStack spacing={2}>
                      {sizes.map((size) => (
                        <Box
                          key={size._id}
                          p={2}
                          borderRadius={'md'}
                          border={'1px solid gray'}
                          bg={isSizeAvailable(size._id) ? (selectedSize && selectedSize._id === size._id ? 'gray.300' : 'gray.200') : 'gray.400'}
                          cursor={isSizeAvailable(size._id) ? 'pointer' : 'not-allowed'}
                          onClick={() => isSizeAvailable(size._id) && handleSizeClick(size)}
                        >
                          {size.name}
                        </Box>
                      ))}
                    </HStack>
                    {selectedSize && (
                      <Text mt={2} fontSize="lg" fontWeight="bold">
                        Tamanho: {selectedSize.name}
                      </Text>
                    )}
                  </>
                )}
              </Box>
              {selectedColor && selectedSize && (
                <Box>
                  <Text fontSize={{ base: '16px', lg: '18px' }} color={'yellow.500'} fontWeight={'500'} textTransform={'uppercase'} mb={'4'}>
                    Quantidade Disponível: {maxQuantity}
                  </Text>
                  <Flex align="center" mb={4}>
                    <Text mr={2}>Quantidade</Text>
                    <Button
                      size="sm"
                      onClick={() => handleQuantityChange(quantity - 1)}
                      isDisabled={quantity <= 1}
                    >
                      -
                    </Button>
                    <Input
                      value={quantity}
                      onChange={(e) => handleQuantityChange(Number(e.target.value))}
                      width="60px"
                      textAlign="center"
                      mx={2}
                    />
                    <Button
                      size="sm"
                      onClick={() => handleQuantityChange(quantity + 1)}
                    >
                      +
                    </Button>
                    <Button ml={4} size="sm" onClick={onOpen}>
                      TABELA DE MEDIDAS
                    </Button>
                  </Flex>
                  <HStack spacing={2}>
                    <Button colorScheme="green" onClick={handleBuy}>
                      Comprar
                    </Button>
                    <Button colorScheme="blue" onClick={() => handleAddToCart(false)}>
                      Adicionar ao Carrinho
                    </Button>
                  </HStack>
                </Box>
              )}
            </Stack>
          )}
        </Stack>
      </SimpleGrid>
      <Divider my={6} />
      {product.description && (
        <Box>
          <Text fontSize="2xl" fontWeight="bold" mb={4}>
            Descrição do Produto
          </Text>
          <Flex direction={{ base: 'column', md: 'row' }} justify="flex-start" align="flex-start" gap={4}>
            <Box flex="0 0 auto" order={{ base: 1, md: 0 }} mb={{ base: 4, md: 0 }} alignSelf={{ base: 'center', md: 'flex-start' }} mr={{ base: 0, md: 4 }}>
              <Image
                borderRadius="full"
                boxSize="150px"
                src={product.images[1] ? `data:image/png;base64,${product.images[1]}` : `data:image/png;base64,${product.images[0]}`}
                alt="Product Image"
                objectFit='cover'
              />
            </Box>
            <Box flex="1" order={{ base: 2, md: 1 }} mt={{ base: 4, md: 0 }}>
              <Text>
                {product.description}
              </Text>
              <Text fontSize={{ base: '16px', lg: '18px' }} color={'yellow.500'} fontWeight={'500'} textTransform={'uppercase'} mb={'4'}>
                Características
              </Text>
              <Text fontSize={'lg'}>{product.details}</Text>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                {product.features.map((featureGroup, index) => (
                  <List spacing={2} key={index}>
                    {featureGroup.map((feature, idx) => (
                      <ListItem key={idx}>{feature}</ListItem>
                    ))}
                  </List>
                ))}
              </SimpleGrid>
              <Text mt={4}>
                As cores dos produtos nas fotos reproduzidas com modelos podem sofrer alterações, em decorrência do uso do flash.
              </Text>
            </Box>
          </Flex>
          <Divider my={6} />
        </Box>
      )}

      {product.productDetails.detail && product.productDetails.detail.length > 0 && (
        <Box>
          <Text fontSize={{ base: '16px', lg: '18px' }} color={'yellow.500'} fontWeight={'500'} textTransform={'uppercase'} mb={'4'}>
            Detalhes Especiais
          </Text>
          <List spacing={2}>
            {product.productDetails.map((detail, idx) => (
              <ListItem key={idx}>
                <Text as={'span'} fontWeight="bold">
                  {detail.label}:
                </Text>{' '}
                {detail.value}
              </ListItem>
            ))}
          </List>
          <Divider my={6} />
        </Box>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Tabela de Medidas</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image src="/path/to/your/image.png" alt="Tabela de Medidas" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      
      <ProductCarousel productId={id} />
    </Container>
  );
};

export default ProductPage;